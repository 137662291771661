import cx from "classnames"
import PropTypes from "prop-types"
import { useTabs } from "components/Tab/Tabs"

export function TabPanel(props) {
  const { children, id, className, ...rest } = props
  const context = useTabs()
  const classNames = cx(className, "relative")

  if (id !== context.currentPanel) {
    return null
  }
  return (
    <div
      className={classNames}
      role="tabpanel"
      id={context.generateTabPanelId(id)}
      aria-labelledby={context.generateTabId(id)}
      {...rest}
    >
      {children}
    </div>
  )
}

TabPanel.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  children: PropTypes.node,
}
