import { Tabs, TabList, Tab, TabPanel } from "components/Tab"
import { useTranslator } from "components/Translator"
import { RawLink } from "components/Link"
import { Tag } from "components/Tag"
import { InlineList } from "components/InlineList"
import { useConnectedUser } from "components/ConnectedUserProvider"
import { Text } from "components/Text"
import React from "react"

export const SearchListsTabs = ({ popularSearches, savedSearches = null }) => {
  const translator = useTranslator()
  const connectedUser = useConnectedUser()

  const showSavedSearches = Boolean(connectedUser) && savedSearches !== null

  const [currentPanel, setCurrentPanel] = React.useState("popular")

  return (
    <Tabs style="link" currentPanel={currentPanel} onChange={setCurrentPanel}>
      <TabList>
        <Tab
          label={translator.trans(
            "SearchListsTabs.popularSearches.tab",
            null,
            "components"
          )}
          id={"popular"}
        />
        {showSavedSearches ? (
          <Tab
            label={translator.trans(
              "SearchListsTabs.savedSearches.tab",
              null,
              "components"
            )}
            id={"saved"}
          />
        ) : null}
      </TabList>
      <TabPanel id={"popular"}>
        {popularSearches.status === "loading" ? <SearchesListSkeleton /> : null}
        {popularSearches.status === "error" ? <Text>Error</Text> : null}
        {popularSearches.status === "success" ? (
          <SearchesList
            searches={popularSearches.value.filter((popularSearch) => !popularSearch.seoOnly)}
            emptyLabel={translator.trans(
              "SearchListsTabs.popularSearches.empty",
              null,
              "components"
            )}
          />
        ) : null}
      </TabPanel>
      {showSavedSearches ? (
        <TabPanel id={"saved"}>
          {savedSearches.status === "loading" ? <SearchesListSkeleton /> : null}
          {savedSearches.status === "error" ? <Text>Error</Text> : null}
          {savedSearches.status === "success" ? (
            <SearchesList
              searches={savedSearches.value}
              emptyLabel={translator.trans(
                "SearchListsTabs.savedSearches.empty",
                null,
                "components"
              )}
            />
          ) : null}
        </TabPanel>
      ) : null}
    </Tabs>
  )
}

const SearchesList = ({ searches }) => {
  return (
    <InlineList separator={null}>
      {searches.map((search) => (
        <InlineList.Item key={search.token}>
          <SearchesListItem key={search.token} search={search} />
        </InlineList.Item>
      ))}
    </InlineList>
  )
}

const SearchesListItem = ({ search }) => {
  return (
    <RawLink
      href={{
        route: "app_search_saved",
        params: { savedSearchToken: search.token },
      }}
    >
      <Tag
        label={search.label}
        color={search.highlighted ? "default-filled" : "default"}
      />
    </RawLink>
  )
}

const SearchesListSkeleton = () => {
  return (
    <InlineList separator={null}>
      {Array.from({ length: 3 }).map((_item, index) => (
        <InlineList.Item key={index}>
          <TagSkeleton />
        </InlineList.Item>
      ))}
    </InlineList>
  )
}

const TagSkeleton = () => {
  return (
    <Tag
      label={
        <span
          className={
            "inline-block w-20 h-3 animate-pulse rounded bg-grey-light"
          }
        />
      }
    />
  )
}
