import cx from "classnames"
import PropTypes from "prop-types"

// function handleArrowKeys(context, onChange, event, nbItems) {
//   if (event.keyCode === 39) {
//     onChange(context.currentIndex + 1 > nbItems ? 0 : context.currentIndex + 1)
//   }
//   if (event.keyCode === 37) {
//     onChange(context.currentIndex - 1 < 0 ? nbItems : context.currentIndex - 1)
//   }
// }

export function TabList(props) {
  const { children, className, ...rest } = props
  const classNames = cx(
    "flex flex-nowrap whitespace-nowrap overflow-x-auto space-x-2",
    className
  )

  return (
    <div className={classNames} {...rest} role="tablist">
      {children}
      {/*React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          isCurrent: context.currentIndex === index,
          id: context.generateTabId(index),
          ariaControls: context.generateTabPanelId(index),
        })
      })*/}
    </div>
  )
}

TabList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
