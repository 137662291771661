import { useAsyncEffect } from "@react-hook/async"
import { client } from "api/client"
import { Button } from "components/Button"
import { useConnectedUser } from "components/ConnectedUserProvider"
import { Layer, Map, MapModal, MapboxProvider, Source } from "components/Map"
import { useRouter } from "components/Router"
import { Spinner } from "components/Spinner"
import { Text } from "components/Text"
import { useTranslator } from "components/Translator"
import differenceInHours from "date-fns/differenceInHours"
import { center, point, featureCollection, lineString, bbox } from "@turf/turf"
import { useEffect, useRef } from "react"
import * as R from "remeda"
import { MapMarkerAlt } from "components/Icon"
import { Section } from "components/Section"

export function BoatPosition({ boat }) {
  const translator = useTranslator()
  const connectedUser = useConnectedUser()

  if (!connectedUser) {
    return null
  }

  return (
    <Section title={translator.trans("BoatPosition.label", null, "components")}>
      <div
        className={
          "flex flex-col space-y-2 lg:flex-row lg:space-y-0 lg:items-center lg:justify-between lg:space-x-4"
        }
      >
        <div className={"shrink-0 flex items-center space-x-2"}>
          <div className={"shrink-0"}>
            <MapMarkerAlt className={"w-4 h-4 text-primary-dark"} />
          </div>

          <div className={"shrink-0"}>
            <Text variant={"body2"}>
              {translator.trans("BoatPosition.label", null, "components")}
            </Text>
          </div>
        </div>

        <div className={"w-full lg:w-auto"}>
          {boat.mmsi ? (
            <BoatPositionModal boat={boat} />
          ) : (
            <div className={"shrink"}>
              <Text variant={"tag"}>
                {translator.trans("BoatPosition.empty", null, "components")}
              </Text>
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}

function BoatPositionMap({ positions }) {
  const mapRef = useRef(null)

  useEffect(() => {
    const uniquePositions = R.uniqBy(
      positions ?? [],
      ({ lat, lng }) => `${lat}-${lng}`
    )

    if (uniquePositions.length < 2) {
      return
    }

    const bounds = getPositionsBounds(uniquePositions)

    mapRef.current?.fitBounds(bounds, {
      padding: 50,
      duration: 500,
    })
  }, [positions])

  const lastPositionsGeoJSON = mmsiPositionsToGeoJSON(positions)
  const lastPositionsCenter = center(lastPositionsGeoJSON)

  return (
    <Map
      ref={mapRef}
      initialViewState={{
        latitude: lastPositionsCenter.geometry.coordinates[1],
        longitude: lastPositionsCenter.geometry.coordinates[0],
        zoom: 12,
      }}
      height={"100%"}
      width={"100%"}
    >
      <BoatPositionLayers mmsiPositions={positions} />
    </Map>
  )
}

function mmsiPositionsToGeoJSON(mmsiPositions) {
  return featureCollection(
    mmsiPositions.map(({ lat, lng }) => point([lng, lat]))
  )
}

function getPositionsBounds(mmsiPositions) {
  const line = lineString(
    mmsiPositions.map((position) => [position.lng, position.lat])
  )

  const bounds = bbox(line)

  return [bounds.slice(0, 2), bounds.slice(2)]
}

function BoatPositionModal({ boat }) {
  const translator = useTranslator()
  const router = useRouter()

  const {
    status,
    value: mmsiPositions,
    error,
  } = useAsyncEffect(async () => {
    const [lastKnownPosition] = boat.mmsiPosition ?? []

    const shouldFetch =
      !lastKnownPosition ||
      differenceInHours(new Date(), new Date(lastKnownPosition.date)) >= 1

    if (!shouldFetch) {
      return boat.mmsiPositions
    }

    const response = await client.get(
      router.generate("app_user_boat_locate", { boatId: boat.id })
    )

    return response.data.data.mmsiPositions
  }, [boat])

  useEffect(() => {
    if (error) {
      console.error(
        "Erreur lors de la récupération des positions du bateau",
        boat.mmsi,
        error
      )
    }
  }, [error, boat.mmsi])

  const [lastKnownPosition] = mmsiPositions ?? []

  return (
    <MapboxProvider accessToken={process.env.MAPBOX_ACCESS_TOKEN}>
      <MapModal
        title={
          lastKnownPosition
            ? translator.trans(
                "BoatPosition.modal.titleWithDate",
                { date: new Date(lastKnownPosition.date).toLocaleDateString() },
                "components"
              )
            : translator.trans("BoatPosition.modal.title", null, "components")
        }
        trigger={
          <Button fullWidth variant={"outline"}>
            {translator.trans("BoatPosition.cta", null, "components")}
          </Button>
        }
        map={
          <>
            {status === "loading" || status === "idle" ? (
              <div className={"h-full w-full flex items-center justify-center"}>
                <Spinner />
              </div>
            ) : null}

            {status === "error" ? (
              <div className={"px-5"}>
                <Text>
                  {error.response?.data?.message ??
                    translator.trans(
                      "BoatPosition.modal.error",
                      null,
                      "components"
                    )}
                </Text>
              </div>
            ) : null}

            {status === "success" ? (
              <BoatPositionMap positions={mmsiPositions ?? []} />
            ) : null}
          </>
        }
      />
    </MapboxProvider>
  )
}

export function BoatPositionLayers({ mmsiPositions }) {
  const [lastPosition, ...otherPositions] = mmsiPositions

  return (
    <>
      <Source
        id="selectedBoatLine"
        type="geojson"
        data={mmsiPositionsToLineString(mmsiPositions)}
      >
        <Layer {...lineLayer} />
      </Source>
      <Source
        id="selectedBoatPositions"
        type="geojson"
        data={mmsiPositionsToGeoJSON(otherPositions)}
      >
        <Layer
          type="circle"
          paint={secondaryPointPaint}
          id="selectedBoatPoints"
        />
      </Source>
      <Source
        id="selectedBoatLastPosition"
        type="geojson"
        data={mmsiPositionsToGeoJSON([lastPosition])}
      >
        <Layer
          type="circle"
          paint={mainPointPaint}
          id="selectedBoatLastPositionPoint"
        />
      </Source>
    </>
  )
}

const lineLayer = {
  id: "line",
  type: "line",
  paint: {
    "line-width": 2,
    "line-color": "#000",
  },
}

const mainPointPaint = {
  "circle-color": "#139EB3",
  "circle-radius": 10,
  "circle-stroke-width": 2,
  "circle-stroke-color": "#fff",
}

const secondaryPointPaint = {
  "circle-color": "#F6A812",
  "circle-radius": 8,
}

const mmsiPositionsToLineString = (positions) => {
  return lineString(positions.map(({ lng, lat }) => [lng, lat]))
}
