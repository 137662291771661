import { useTranslator } from "components/Translator"
import {
  TestimonyCard,
  TestimonyCardSkeleton,
} from "domains/testimony/TestimonyCard"
import { Wrapper } from "components/Wrapper"
import { Section } from "components/Section"
import { SlidingList } from "components/SlidingList"
import { Text } from "components/Text"

export const TestimoniesSection = ({
  testimonies: testimoniesProps,
  status = "success",
}) => {
  const translator = useTranslator()
  const testimonies =
    status === "loading" ? Array.from({ length: 4 }) : testimoniesProps

  return (
    <Section>
      <Section.Title>
        <Wrapper>
          <div className="flex gap-10">
            {translator.trans("TestimoniesSection.title", null, "components")}
            {/* <script src="https://grwapi.net/widget.min.js"></script> needs to be injected in the twig page */}
            <div className="review-widget_net"
                 data-uuid={translator.trans("club.testimony.index.grwapiUuid", null, "pages")} data-template="2"
                 data-lang={translator.locale} data-theme="light"></div>
          </div>
        </Wrapper>
      </Section.Title>
      {["loading", "success"].includes(status) ? (
        testimonies.length > 0 ? (
          <>
            <div className="lg:hidden">
              <SlidingList
                items={testimonies.map((testimony, index) => (
                  <div key={testimony?.id ?? index} className="w-72 shrink-0">
                    {status === "loading" ? (
                      <TestimonyCardSkeleton />
                    ) : (
                      <TestimonyCard testimony={testimony} />
                    )}
                  </div>
                ))}
              />
            </div>
            <div className="hidden lg:block">
              <Wrapper>
                <div className="grid grid-cols-4 gap-6">
                  {testimonies.map((testimony, index) =>
                    status === "loading" ? (
                      <TestimonyCardSkeleton key={index} />
                    ) : (
                      <TestimonyCard key={testimony.id} testimony={testimony} />
                    )
                  )}
                </div>
              </Wrapper>
            </div>
          </>
        ) : (
          <Text>
            {translator.trans("TestimoniesSection.empty", null, "components")}
          </Text>
        )
      ) : null}

      {status === "error" ? (
        <Text>
          {translator.trans("TestimoniesSection.error", null, "components")}
        </Text>
      ) : null}
    </Section>
  )
}
