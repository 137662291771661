import { Card } from "components/Card"
import { Text } from "components/Text"
import { UserAvatar } from "domains/user/UserAvatar"
import { useTranslator } from "components/Translator"
import styles from "./ForumPostCard.css?module"
import { useRouter } from "components/Router"
import { ForumPostFollowButton } from "./ForumPostFollowButton"
import { AvatarSkeleton } from "components/AvatarSkeleton"

export const ForumPostCard = (props) => {
  const translator = useTranslator()
  const router = useRouter()

  const { post: postProps } = props
  const post = postProps.parentPost ?? postProps

  return (
    <div className={"relative"}>
      <Card>
        <div className={"py-1 px-2 grid gap-2"}>
          <div className={"flex gap-2 justify-between items-center"}>
            <Text tag={"p"} variant={"caption"}>
              {translator.transChoice(
                "ForumPostCard.nbVisits",
                post.nbVisits,
                { nb: post.nbVisits },
                "components"
              )}
            </Text>
            {post.following !== undefined ? (
              <div className={"relative z-10"}>
                <ForumPostFollowButton
                  postId={post.id}
                  initialFollowing={post.following}
                  size={"small"}
                  onSuccess={props.onFollowToggle}
                />
              </div>
            ) : null}
          </div>
          <div className={"flex gap-3"}>
            <div className={"shrink-0 grow-0"}>
              <UserAvatar user={post.user} size={"small"} />
            </div>
            <div className={"shrink grow basis-full"}>
              <Text
                tag={"a"}
                href={router.generate("app_club_forum_post", {
                  forumTagSlug: post.tag.slug,
                  forumPostSlug: post.slug,
                })}
                variant={"body2"}
                className={styles.PostCard__title}
              >
                {post.title}
              </Text>
            </div>
          </div>
          <div>
            <Text variant={"caption"}>
              {translator.transChoice(
                "ForumPostCard.nbReplies",
                post.nbReplies,
                { nb: post.nbReplies },
                "components"
              )}
            </Text>
            <Text variant={"caption"}>
              {translator.trans(
                "ForumPostCard.createdAt",
                { date: new Date(post.createdAt).toLocaleDateString() },
                null
              )}
            </Text>
            {post.nbReplies > 0 ? (
              <Text variant={"caption"}>
                {translator.trans(
                  "ForumPostCard.updatedAt",
                  { date: new Date(post.updatedAt).toLocaleDateString() },
                  null
                )}
              </Text>
            ) : null}
          </div>
        </div>
      </Card>
    </div>
  )
}

export const ForumPostCardSkeleton = () => {
  return (
    <div className={"relative"}>
      <Card>
        <div className={"py-1 px-2 grid gap-2 animate-pulse"}>
          <div className={"w-full h-4 rounded bg-grey-light"}></div>
          <div className={"flex gap-3"}>
            <div className={"shrink-0 grow-0"}>
              <AvatarSkeleton size={"small"} />
            </div>
            <div className={"shrink grow basis-full grid gap-2"}>
              <div className={"w-full h-5 rounded bg-grey-light"}></div>
              <div className={"w-full h-5 rounded bg-grey-light"}></div>
            </div>
          </div>
          <div className={"grid gap-1"}>
            <div className={"w-full h-4 rounded bg-grey-light"}></div>
            <div className={"w-full h-4 rounded bg-grey-light"}></div>
            <div className={"w-full h-4 rounded bg-grey-light"}></div>
          </div>
        </div>
      </Card>
    </div>
  )
}
