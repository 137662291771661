import { useTranslator } from "components/Translator"
import { Wrapper } from "components/Wrapper"
import { Text } from "components/Text"
import { HourglassHalf, Users, Bullhorn, Comments } from "components/Icon"
import { Separator } from "components/Separator"
import { useBreakpoints } from "hooks/useBreakpoints"
import cx from "classnames"
import { Fragment } from "react"

export const Metrics = ({ metrics, color, title, excerpt }) => {
  const translator = useTranslator()
  const currentYear = new Date().getFullYear()
  const { lg } = useBreakpoints()

  const Wrap = lg ? Wrapper : "div"

  return (
    <Wrap>
      <section
        className={cx("p-10 lg:rounded-lg", {
          "bg-primary-darker": color === "primary",
          "bg-secondary-darker": color === "secondary",
        })}
      >
        <div className="space-y-8">
          <div className="space-y-4 text-center text-white">
            <Text variant="headline2" tag="h2">
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    title ||
                    translator.trans("Metrics.title", null, "components"),
                }}
              />
            </Text>
            {excerpt ? (
              <Text tag="p" variant="caption">
                {excerpt}
              </Text>
            ) : null}
          </div>
          {metrics ? (
            <div className="px-5 space-y-8 lg:flex lg:flex-row lg:justify-center lg:px-0 lg:space-y-0 lg:space-x-16">
              <div className="grid grid-cols-2 gap-x-6 gap-y-8 lg:grid-cols-2">
                <MetricCircle
                  icon={<HourglassHalf className="w-5" />}
                  label={translator.trans(
                    "Metrics.existence",
                    {
                      nbYears: currentYear - metrics.creationDate,
                    },
                    "components"
                  )}
                  color={color}
                />
                <MetricCircle
                  icon={<Users className="w-8" />}
                  label={translator.trans(
                    "Metrics.users",
                    {
                      nbUsers: metrics.nbUsers.toLocaleString(
                        translator.locale
                      ),
                    },
                    "components"
                  )}
                  color={color}
                />
                <MetricCircle
                  icon={<Bullhorn className="w-6" />}
                  label={translator.trans(
                    "Metrics.adverts",
                    {
                      nbAdverts: metrics.nbAdverts.toLocaleString(
                        translator.locale
                      ),
                    },
                    "components"
                  )}
                  color={color}
                />
                <MetricCircle
                  icon={<Comments className="w-8" />}
                  label={translator.trans(
                    "Metrics.messages",
                    {
                      nbMessages: metrics.nbMessages.toLocaleString(
                        translator.locale
                      ),
                    },
                    "components"
                  )}
                  color={color}
                />
              </div>
              {metrics.nbConnectedOwner !== undefined &&
              metrics.nbConnectedCrew !== undefined ? (
                <Fragment>
                  <Separator vertical={lg} color={color} />
                  <div className="space-y-4">
                    <Text
                      variant="headline4"
                      tag="h3"
                      className="text-white lg:text-center"
                    >
                      {translator.trans(
                        "Metrics.connections.title",
                        null,
                        "components"
                      )}
                    </Text>
                    <div className="grid gap-4 lg:flex lg:flex-col lg:place-content-center lg:space-y-4">
                      <Metric
                        figure={metrics.nbConnectedOwner.toLocaleString(
                          translator.locale
                        )}
                        label={translator.trans(
                          "Metrics.connections.owners",
                          null,
                          "components"
                        )}
                        color={color}
                      />
                      <Metric
                        figure={metrics.nbConnectedCrew.toLocaleString(
                          translator.locale
                        )}
                        label={translator.trans(
                          "Metrics.connections.crew",
                          null,
                          "components"
                        )}
                        color={color}
                      />
                    </div>
                  </div>
                </Fragment>
              ) : null}
            </div>
          ) : null}
        </div>
      </section>
    </Wrap>
  )
}

const MetricCircle = ({ icon, label, color }) => {
  return (
    <div className="flex flex-col items-center space-y-2">
      <div
        className={cx(
          "flex w-16 h-16 rounded-full items-center justify-center text-white",
          {
            "bg-primary-default": color === "primary",
            "bg-secondary-default": color === "secondary",
          }
        )}
      >
        {icon}
      </div>
      <Text
        variant="headline5"
        className={cx("text-center", {
          "text-primary-lighter": color === "primary",
          "text-secondary-lighter": color === "secondary",
        })}
      >
        {label}
      </Text>
    </div>
  )
}

const Metric = ({ figure, label, color }) => {
  return (
    <p className="flex items-center space-x-2 lg:flex-col lg:justify-center lg:space-y-2">
      <Text variant="headline2" tag="span" className="text-white">
        {figure}
      </Text>
      <Text
        variant="body1"
        tag="span"
        className={cx({
          "text-primary-lighter": color === "primary",
          "text-secondary-lighter": color === "secondary",
        })}
      >
        {label}
      </Text>
    </p>
  )
}
