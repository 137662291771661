import { Card, CardContent } from "components/Card"
import { RawLink } from "components/Link"
import { Image } from "components/Image"
import { useRouter } from "components/Router"

export const PartnerCard = ({ partner }) => {
  const router = useRouter()

  return (
    <Card spacing="small">
      <CardContent>
        <RawLink
          className="block h-32"
          href={router.generate("app_club_partner_show", {
            partnerSlug: partner.slug,
          })}
        >
          <Image
            imageFile={partner.imageFile}
            size="w640"
            alt={partner.name}
            className="w-full h-full object-contain"
          />
        </RawLink>
      </CardContent>
    </Card>
  )
}

export const PartnerCardSkeleton = () => {
  return (
    <Card spacing="small">
      <CardContent>
        <div className={"rounded bg-grey-light animate-pulse h-32"}></div>
      </CardContent>
    </Card>
  )
}
