import { Avatar } from "components/Avatar"

export const AvatarSkeleton = ({ size }) => {
  return (
    <Avatar size={size} fallback={<div className={"bg-grey-light h-full"} />} />
  )
}

AvatarSkeleton.propTypes = {
  size: Avatar.propTypes.size,
}
