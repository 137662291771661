import PropTypes from "prop-types"
import * as React from "react"

export function Tabs(props) {
  const { id: idProps, currentPanel, onChange, children, style } = props
  const autoId = `Tabs-${React.useId()}`
  const id = idProps || autoId

  const generateTabId = React.useCallback((index) => `${id}-tab-${index}`, [id])

  const generateTabPanelId = React.useCallback(
    (index) => `${id}-tabpanel-${index}`,
    [id]
  )

  return (
    <TabsContext.Provider
      value={{
        id,
        currentPanel,
        onChange,
        generateTabId,
        generateTabPanelId,
        style,
      }}
    >
      <div className="space-y-4 lg:space-y-6" role="tabs">
        {children}
      </div>
    </TabsContext.Provider>
  )
}

Tabs.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  currentPanel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  style: PropTypes.oneOf(["default", "link"]),
}

Tabs.defaultProps = {
  style: "default",
}

export const TabsContext = React.createContext()

export function useTabs() {
  const context = React.useContext(TabsContext)

  if (context === undefined) {
    throw new Error(
      "`useTabs` must be used in a component wrapped in as `Tabs` component"
    )
  }

  return context
}
