import { useRouter } from "components/Router"
import { useAsyncEffect } from "@react-hook/async"
import { client } from "api/client"

/**
 * @param {Scope} scope
 */
export const useHomePartialData = (scope) => {
  const router = useRouter()

  const request = useAsyncEffect(async () => {
    const response = await client.get(
      router.generate("app_home_partial", { scope })
    )

    return response.data.data
  }, [])

  return request
}

/**
 * @typedef {
   | "bannerInfos"
   | "selectedMedias"
   | "lastForumPosts"
   | "lastConnectedUsers"
   | "selectedBoats"
   | "footerPartners"
   | "testimonies"
   | "articles"
   | "boats"
   | "savedSearches"
   | "popularSearches"
   | "homeSlides"
   | "promoBanner"
   } Scope
 */
