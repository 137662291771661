/**
 * @param {IllustratedSectionProps} props
 */
export const IllustratedSection = ({ children, image, imageAlt }) => {
  return (
    <section className="relative">
      <img
        src={image}
        alt={imageAlt ?? ""}
        className={"absolute inset-0 object-cover object-bottom w-full h-full"}
      />
      <div className={"relative"}>{children}</div>
    </section>
  )
}

/**
 * @typedef {Object} IllustratedSectionProps
 * @prop {import("react").ReactNode} children
 * @prop {string} image
 * @prop {string} [imageAlt]
 */
