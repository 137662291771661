import PropTypes from "prop-types"
import * as React from "react"
import { Modal } from "components/Modal"

export const MapModal = ({ trigger, map, ...modalProps }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div>
      {React.cloneElement(trigger, { onClick: () => setIsOpen(true) })}
      <Modal
        {...modalProps}
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        <div className="w-full h-screen-2/3 lg:w-128 lg:h-128">
          {React.cloneElement(map, { height: "100%", width: "100%" })}
        </div>
      </Modal>
    </div>
  )
}

MapModal.propTypes = {
  trigger: PropTypes.element.isRequired,
  map: PropTypes.element.isRequired,
}
