import React from "react"
import { Tabs } from "components/Tab/Tabs"

export const HashTabs = ({ children, defaultPanel }) => {
  const [currentPanel, setCurrentPanel] = React.useState(
    getHash() || defaultPanel
  )

  React.useEffect(() => {
    window.history.pushState(null, "", `#${currentPanel}`)
  }, [currentPanel])

  return (
    <Tabs currentPanel={currentPanel} onChange={setCurrentPanel}>
      {children}
    </Tabs>
  )
}

const getHash = () => {
  return window.location.hash.slice(1)
}
