import { Wrapper } from "components/Wrapper"
import { Button } from "components/Button"
import { useTranslator } from "components/Translator"
import { Text } from "components/Text"
import { Card, CardContent } from "components/Card"
import { AdvertInteractionsProvider } from "domains/advert/AdvertInteractionsProvider"
import { RawLink } from "components/Link"
import { Metrics } from "components/Metrics"
import { Separator } from "components/Separator"
import cx from "classnames"
import { TestimoniesSection as StaticTestimoniesSection } from "domains/testimony/TestimoniesSection"
import { useConnectedUser } from "components/ConnectedUserProvider"
import { Section } from "components/Section"
import * as MediaType from "enums/MediaType"
import { useAsyncEffect } from "@react-hook/async"
import { useRouter } from "components/Router"
import { useEffect } from "react"
import * as SuggestType from "enums/SuggestType"
import { Pen, PlayCircle } from "components/Icon"
import { PressSection } from "components/PressSection"
import { AdvertResult, UserResult } from "components/SearchResult"
import { client } from "api/client"
import { Loader } from "components/Loader"
import { Image } from "components/Image"
import { SearchListsTabs as StaticSearchListsTabs } from "components/SearchListsTabs"
import { PartnerCard, PartnerCardSkeleton } from "domains/partner/PartnerCard"
import { SlidingList } from "components/SlidingList"
import { IllustratedSection } from "components/IllustratedSection"
import { ActivitySection } from "components/ActivitySection"
import { BoatsMap } from "components/BoatsMap"
import { UserAvatar } from "domains/user/UserAvatar"
import styles from "./style.css?module"
import {
  ForumPostCard,
  ForumPostCardSkeleton,
} from "domains/forum-post/ForumPostCard"
import { AutoForm } from "components/Form/AutoForm"
import { getInitialValue } from "components/Form"
import { AvatarSkeleton } from "components/AvatarSkeleton"
import { useHomePartialData } from "hooks/useHomePartialData"
import { Carousel } from "components/Carousel"
import { PromoBanner } from "components/PromoBanner"
import { useFormRequestContext } from "@w3rone/json-schema-form"
import classNames from "classnames"

export function HomeIndexPage({ form, metrics, navigationType }) {
  const connectedUser = useConnectedUser()
  const translator = useTranslator()

  const bannerInfosSection = connectedUser ? <BannerInfosSection /> : null
  const selectedMediasSection = connectedUser ? <SelectedMediasSection /> : null
  const selectedBoatsSection = connectedUser ? <SelectedBoatsSection /> : null
  const blogArticlesSection =
    process.env.PROJECT_CONTEXT !== "coboaters" ? <BlogArticlesSection /> : null
  const forumSection = <ForumSection />

  return (
    <div>
      <PromoBanner />
      <div
        className={classNames("space-y-5 lg:space-y-20", {
          "first:mt-6": connectedUser,
        })}
      >
        <Header form={form} />

        <div className="flex flex-col space-y-5">
          {bannerInfosSection}
          {blogArticlesSection}
          {forumSection}
          {selectedMediasSection}
          {selectedBoatsSection}
        </div>

        <Suggestions navigationType={navigationType} />

        {metrics && !connectedUser ? (
          <Metrics
            metrics={metrics}
            color="primary"
            title={translator.trans("home.index.metrics.title", null, "pages")}
            excerpt={translator.trans(
              "home.index.metrics.excerpt",
              null,
              "pages"
            )}
          />
        ) : null}

        {!connectedUser ? <TestimoniesSection /> : null}

        <ActivitySection />

        {!connectedUser ? <PressSection /> : null}

        {connectedUser ? <BoatsMap /> : null}
      </div>

      <PartnersSection />
    </div>
  )
}

const Header = ({ form }) => {
  const translator = useTranslator()
  const connectedUser = useConnectedUser()

  return (
    <section className="space-y-5">
      {!connectedUser ? <Hero /> : null}
      <Wrapper>
        <Card>
          <CardContent>
            <div className="space-y-4">
              <AutoForm
                schema={form}
                initialValue={{
                  [form.title]: {
                    ...getInitialValue(form, null),
                    type: "boat",
                  },
                }}
                components={{
                  ActionsWrapper: ActionsWrapper,
                  Actions: Actions,
                }}
                submitLabel={translator.trans(
                  "home.index.form.submitLabel",
                  null,
                  "pages"
                )}
              />
            </div>
          </CardContent>
          <CardContent className="bg-grey-lighter">
            <SearchListsTabs />
          </CardContent>
        </Card>
      </Wrapper>
    </section>
  )
}

const Hero = () => {
  const { status, value, error } = useHomePartialData("homeSlides")
  const translator = useTranslator()

  useEffect(() => {
    if (status === "error") {
      console.error(error)
    }
  }, [status, error])

  if (status === "loading") {
    return <div className={"h-[480px] bg-grey-light animate-pulse"}></div>
  }

  if (status === "error") {
    return <p>{translator.trans("home.index.carousel.error", null, "pages")}</p>
  }

  if (status === "success") {
    return (
      <Carousel
        slides={value.map((item, index) => (
          <IllustratedSection
            key={item.id}
            image={item.imageFile.thumbs.w1920}
            imageAlt={item.imageAlt}
          >
            <div className="pt-8 h-[480px] flex flex-col items-center gap-10">
              <Text
                variant="headline1"
                tag={index === 0 ? "h1" : "h2"}
                className="text-shadow-lg text-center text-white"
              >
                {item.title}
              </Text>

              {item.cta && item.url ? (
                <Button href={item.url}>{item.cta}</Button>
              ) : null}
            </div>
          </IllustratedSection>
        ))}
        interval={4000}
        components={{
          PreviousButton: () => null,
          NextButton: () => null,
          ActiveSlideIndicator: () => null,
          BulletsContainer: HeroCarouselBulletsContainer,
        }}
      />
    )
  }

  return null
}

const HeroCarouselBulletsContainer = ({ children }) => {
  return (
    <div
      className={
        "absolute bottom-5 inset-x-0 flex items-center justify-center gap-3"
      }
    >
      {children}
    </div>
  )
}

const ActionsWrapper = ({ children }) => {
  return (
    <div className="flex flex-col space-y-4 md:flex-row md:justify-end md:space-y-0 md:space-x-4 mt-5">
      {children}
    </div>
  )
}

const Actions = ({ status, submitLabel }) => {
  const request = useFormRequestContext()
  const translator = useTranslator()

  return (
    <>
      <Button
        type="submit"
        color="secondary"
        disabled={["loading", "success"].includes(status)}
        busy={request.status === "loading"}
      >
        {submitLabel}
      </Button>
      <Button
        variant="text"
        color="secondary"
        name="advanced_search"
        value="true"
        type="submit"
      >
        {translator.trans("home.index.form.advancedSearchCTA", null, "pages")}
      </Button>
    </>
  )
}

const BannerInfosSection = () => {
  const translator = useTranslator()
  const bannerInfos = useHomePartialData("bannerInfos")

  return (
    <Section>
      <Wrapper>
        <Section.Title size="small">
          {translator.trans("home.index.bannerInfos.title", null, "pages")}
        </Section.Title>

        <div className="[--gap:_8px] overflow-x-auto flex flex-nowrap w-[calc(100%_+_var(--gap))] mx-[calc(-1_*_var(--gap))]">
          {bannerInfos.status === "loading"
            ? Array.from({ length: 3 }).map((_item, index) => {
                return (
                  <div key={index} className={styles.item}>
                    <BannerInfoSkeleton />
                  </div>
                )
              })
            : null}

          {bannerInfos.status === "success" ? (
            bannerInfos.value.length > 0 ? (
              bannerInfos.value.map((bannerInfo) => (
                <div key={bannerInfo.id} className={styles.item}>
                  <BannerInfoCard bannerInfo={bannerInfo} />
                </div>
              ))
            ) : (
              <Text>
                {translator.trans(
                  "home.index.bannerInfos.empty",
                  null,
                  "pages"
                )}
              </Text>
            )
          ) : null}

          {bannerInfos.status === "error" ? (
            <Text>
              {translator.trans("home.index.bannerInfos.error", null, "pages")}
            </Text>
          ) : null}
        </div>
      </Wrapper>
    </Section>
  )
}

const BlogArticlesSection = () => {
  const translator = useTranslator()
  const articles = useHomePartialData("articles")

  return (
    <Section>
      <Wrapper>
        <Section.Title size="small">
          {translator.trans("home.index.blogArticles.title", null, "pages")}
        </Section.Title>

        <div className="[--gap:_8px] overflow-x-auto flex flex-nowrap w-[calc(100%_+_var(--gap))] mx-[calc(-1_*_var(--gap))]">
          {articles.status === "loading"
            ? Array.from({ length: 3 }).map((_item, index) => {
                return (
                  <div key={index} className={styles.item}>
                    <BlogArticleCardSkeleton />
                  </div>
                )
              })
            : null}

          {articles.status === "success" ? (
            articles.value.length > 0 ? (
              articles.value.map((article) => (
                <div key={article.title} className={styles.item}>
                  <BlogArticleCard blogArticle={article} />
                </div>
              ))
            ) : (
              <Text>
                {translator.trans(
                  "home.index.blogArticles.empty",
                  null,
                  "pages"
                )}
              </Text>
            )
          ) : null}

          {articles.status === "error" ? (
            <Text>
              {translator.trans("home.index.blogArticles.error", null, "pages")}
            </Text>
          ) : null}
        </div>
      </Wrapper>
    </Section>
  )
}

const BlogArticleCard = ({ blogArticle }) => {
  return (
    <Card spacing="small">
      <CardContent>
        <BlogArticleInfo blogArticle={blogArticle} />
      </CardContent>
    </Card>
  )
}

const BlogArticleInfo = ({ blogArticle }) => {
  const translator = useTranslator()

  const info = (
    <div className="flex flex-col">
      <Section.Title>
        <Text tag="p" variant="body2" className="grow">
          {blogArticle.title}
        </Text>
      </Section.Title>
      <Text tag="p" className="mt-auto text-light" variant="caption">
        {blogArticle.type} -{" "}
        {new Date(blogArticle.createdAt).toLocaleDateString(translator.locale)}
      </Text>
    </div>
  )

  if (blogArticle.url) {
    return (
      <RawLink external href={blogArticle.url}>
        <div className="grid gap-2 grid-cols-[2fr_4fr]">
          <div className="flex">
            <img
              className="flex-grow-0 self-center"
              src={blogArticle.img}
              alt=""
              loading={"lazy"}
            />
          </div>
          {info}
        </div>
      </RawLink>
    )
  }

  return info
}

const BlogArticleCardSkeleton = () => {
  return (
    <Card spacing="small">
      <CardContent>
        <div className="grid gap-2 grid-cols-[2fr_4fr] animate-pulse">
          <div className="flex">
            <div className={"rounded aspect-video bg-grey-light w-full"}></div>
          </div>
          <div className="flex flex-col">
            <div className={"grid gap-1"}>
              <div className={"rounded bg-grey-light w-full h-6"}></div>
              <div className={"rounded bg-grey-light w-full h-6"}></div>
              <div className={"rounded bg-grey-light w-full h-6"}></div>
            </div>
            <div className={"rounded bg-grey-light w-full h-4"}></div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

const ForumSection = () => {
  const translator = useTranslator()
  const forumPosts = useHomePartialData("lastForumPosts")

  return (
    <Section>
      <Wrapper>
        <Section.Title size="small">
          {translator.trans("home.index.forumPosts.title", null, "pages")}
        </Section.Title>

        <div className="[--gap:_8px] overflow-x-auto flex flex-nowrap w-[calc(100%_+_var(--gap))] mx-[calc(-1_*_var(--gap))]">
          {forumPosts.status === "loading"
            ? Array.from({ length: 3 }).map((_item, index) => {
                return (
                  <div key={index} className={styles.item}>
                    <ForumPostCardSkeleton />
                  </div>
                )
              })
            : null}

          {forumPosts.status === "success" ? (
            forumPosts.value.length > 0 ? (
              forumPosts.value.map((forumPost) => (
                <div key={forumPost.id} className={styles.item}>
                  <ForumPostCard post={forumPost} />
                </div>
              ))
            ) : (
              <Text>
                {translator.trans("home.index.forumPosts.empty", null, "pages")}
              </Text>
            )
          ) : null}

          {forumPosts.status === "error" ? (
            <Text>
              {translator.trans("home.index.forumPosts.error", null, "pages")}
            </Text>
          ) : null}
        </div>
      </Wrapper>
    </Section>
  )
}

const BannerInfoCard = ({ bannerInfo }) => {
  return (
    <Card spacing="small">
      <CardContent>
        <BannerInfo bannerInfo={bannerInfo} />
      </CardContent>
    </Card>
  )
}

const BannerInfo = ({ bannerInfo }) => {
  const translator = useTranslator()

  const info = (
    <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
      <Text tag="p" variant="body2" className="grow">
        {bannerInfo.message}
      </Text>
      <Text tag="p" variant="tag" className="text-light shrink-0">
        {new Date(bannerInfo.updatedAt).toLocaleDateString(translator.locale)}
      </Text>
    </div>
  )

  if (bannerInfo.url) {
    return (
      <RawLink external href={bannerInfo.url}>
        {info}
      </RawLink>
    )
  }

  return info
}

const BannerInfoSkeleton = () => {
  return (
    <Card spacing="small">
      <CardContent>
        <div className="flex flex-col md:flex-row md:items-center md:space-x-4 animate-pulse">
          <div className={"w-full grid gap-1"}>
            <div className={"h-3 bg-grey-light rounded"} />
            <div className={"h-3 bg-grey-light rounded"} />
            <div className={"h-3 bg-grey-light rounded"} />
            <div className={"h-3 bg-grey-light rounded"} />
          </div>
          <div className={"h-2 w-full bg-grey-light rounded"} />
        </div>
      </CardContent>
    </Card>
  )
}

const AdvertsSection = ({ adverts, title, moreLabel, moreHref, layout }) => {
  const translator = useTranslator()

  return (
    <SectionWithMore
      title={title}
      moreLabel={moreLabel}
      moreHref={moreHref}
      layout={layout}
    >
      {adverts.length > 0 ? (
        <div className="space-y-6">
          <AdvertInteractionsProvider ids={adverts.map((advert) => advert.id)}>
            <div
              className={cx("grid gap-6", {
                "lg:grid-cols-3": adverts.length >= 3,
                "lg:grid-cols-2": adverts.length < 3,
              })}
            >
              {adverts.map((advert) => {
                return (
                  <div className="min-w-0" key={advert.id}>
                    <AdvertResult advert={advert} />
                  </div>
                )
              })}
            </div>
          </AdvertInteractionsProvider>
        </div>
      ) : (
        <div className="space-y-2">
          <Text tag="p">
            {translator.trans(
              "home.index.advertSuggestions.empty.message",
              null,
              "pages"
            )}
          </Text>
          <div className="text-center">
            <Button
              variant="text"
              color="secondary"
              iconLeft={<Pen className="w-4" />}
              href={{ route: "app_user_account_edit" }}
            >
              {translator.trans("home.index.advertSuggestions.empty.cta")}
            </Button>
          </div>
        </div>
      )}
    </SectionWithMore>
  )
}

const UsersSection = ({ users, layout, moreHref }) => {
  const translator = useTranslator()

  return (
    <SectionWithMore
      title={translator.trans("home.index.users.title", null, "pages")}
      moreHref={moreHref}
      moreLabel={translator.trans("home.index.users.more", null, "pages")}
      layout={layout}
    >
      <div className="grid gap-6 md:grid-cols-1">
        {users.map((user) => {
          return <UserResult key={user.id} user={user} />
        })}
      </div>
    </SectionWithMore>
  )
}

const SectionWithMore = ({ children, title, moreLabel, moreHref, layout }) => {
  const moreBtn = (
    <Button href={moreHref} variant="text" color="secondary">
      {moreLabel}
    </Button>
  )

  return (
    <Section>
      <Section.Title>
        <div className="flex justify-between items-center">
          <div className="leading-loose text-dark">{title}</div>
          <div
            className={cx("shrink-0 hidden", {
              "lg:block": layout === "horizontal",
            })}
          >
            {moreBtn}
          </div>
        </div>
      </Section.Title>
      <div className="space-y-6">
        <div>{children}</div>
        <div
          className={cx("flex justify-center", {
            "lg:hidden": layout === "horizontal",
          })}
        >
          {moreBtn}
        </div>
      </div>
    </Section>
  )
}

const SelectedMediasSection = () => {
  const translator = useTranslator()
  const selectedMedias = useHomePartialData("selectedMedias")

  return (
    <Section>
      <Wrapper>
        <Section.Title size="small">
          {translator.trans("home.index.selectedMedias.title", null, "pages")}
        </Section.Title>
        <div className="[--gap:_8px] overflow-x-auto flex flex-nowrap w-[calc(100%_+_var(--gap))] mx-[calc(-1_*_var(--gap))]">
          {selectedMedias.status === "loading"
            ? Array.from({ length: 3 }).map((_item, index) => {
                return (
                  <div key={index} className={styles.item}>
                    <SelectedMediaSkeleton />
                  </div>
                )
              })
            : null}

          {selectedMedias.status === "success" ? (
            selectedMedias.value.length > 0 ? (
              selectedMedias.value.map((selectedMedia) => (
                <div key={selectedMedia.id} className={styles.item}>
                  <SelectedMediaCard media={selectedMedia} />
                </div>
              ))
            ) : (
              <Text>
                {translator.trans(
                  "home.index.selectedMedias.empty",
                  null,
                  "pages"
                )}
              </Text>
            )
          ) : null}

          {selectedMedias.status === "error" ? (
            <Text>{translator.trans("home.index.selectedMedias.error")}</Text>
          ) : null}
        </div>
      </Wrapper>
    </Section>
  )
}

const SelectedMediaCard = ({ media }) => {
  return (
    <Card spacing="small">
      <CardContent>
        <SelectedMedia media={media} />
      </CardContent>
    </Card>
  )
}

const SelectedMedia = ({ media }) => {
  const router = useRouter()
  const translator = useTranslator()
  return (
    <div className="flex flex-col space-y-2">
      <RawLink
        href={router.generate("app_account_album_media", {
          userId: media.album.user.id,
          mediaAlbumId: media.album.id,
          mediaId: media.id,
        })}
      >
        <MediaPreview media={media} />
      </RawLink>
      <div>
        {media.legend ? (
          <Text variant="headline5" className="text-primary-dark line-clamp-2">
            {media.legend}
          </Text>
        ) : null}
      </div>
      <RawLink
        href={router.generate("app_account_profile_show", {
          userId: media.album.user.id,
        })}
      >
        <div className="flex flex-col items-center space-y-2 lg:flex-row lg:justify-between lg:space-y-0">
          <div className="flex flex-row items-center space-x-4">
            <UserAvatar user={media.album.user} size="small" />
            <Text>{media.album.user.fullName}</Text>
          </div>
          <Text tag="p" className="text-light" variant="caption">
            {translator.trans(
              "home.index.selectedMedias.nbVisits",
              { nbVisits: media.nbVisits },
              "pages"
            )}{" "}
            - {new Date(media.updatedAt).toLocaleDateString(translator.locale)}
          </Text>
        </div>
      </RawLink>
    </div>
  )
}

const MediaPreview = ({ media }) => {
  const preview =
    media.type.enum_value === MediaType.PHOTO ? (
      <Image
        imageFile={media.imageFile}
        size="w400"
        alt={media.legend}
        className="object-cover object-center w-full h-full"
      />
    ) : (
      <PlayCircle className="w-16" />
    )

  return (
    <div className="flex justify-center items-center w-full h-full text-white bg-black aspect-video">
      {preview}
    </div>
  )
}

const SelectedMediaSkeleton = () => {
  return (
    <Card spacing="small">
      <CardContent>
        <div className="flex flex-col space-y-2 animate-pulse">
          <div className={"rounded aspect-video bg-grey-light"} />
          <div className="flex flex-col items-center space-y-2 lg:flex-row lg:justify-between lg:space-y-0">
            <div className="flex flex-row items-center space-x-4 w-full">
              <div className={"shrink-0"}>
                <AvatarSkeleton size={"small"} />
              </div>
              <div className={"rounded h-2 w-full grow bg-grey-light"} />
              <div className={"rounded h-2 w-full grow bg-grey-light"} />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

const SelectedBoatsSection = () => {
  const translator = useTranslator()
  const selectedBoats = useHomePartialData("selectedBoats")

  return (
    <Section>
      <Wrapper>
        <Section.Title size="small">
          {translator.trans("home.index.selectedBoats.title", null, "pages")}
        </Section.Title>
        <div className="[--gap:_8px] overflow-x-auto flex flex-nowrap w-[calc(100%_+_var(--gap))] mx-[calc(-1_*_var(--gap))]">
          {selectedBoats.status === "loading"
            ? Array.from({ length: 3 }).map((_item, index) => {
                return (
                  <div key={index} className={styles.item}>
                    <SelectedBoatSkeleton />
                  </div>
                )
              })
            : null}

          {selectedBoats.status === "success" ? (
            selectedBoats.value.length > 0 ? (
              selectedBoats.value.map((selectedBoat) => (
                <div key={selectedBoat.id} className={styles.item}>
                  <SelectedBoatCard boat={selectedBoat} />
                </div>
              ))
            ) : (
              <Text>
                {translator.trans(
                  "home.index.selectedBoats.empty",
                  null,
                  "pages"
                )}
              </Text>
            )
          ) : null}

          {selectedBoats.status === "error" ? (
            <Text>
              {translator.trans(
                "home.index.selectedBoats.error",
                null,
                "pages"
              )}
            </Text>
          ) : null}
        </div>
      </Wrapper>
    </Section>
  )
}

const SelectedBoatCard = ({ boat }) => {
  const router = useRouter()

  return (
    <RawLink
      href={router.generate("app_account_boat_show", {
        userId: boat.user.id,
        boatId: boat.id,
      })}
    >
      <Card spacing="small">
        <CardContent>
          <SelectedBoat boat={boat} />
        </CardContent>
      </Card>
    </RawLink>
  )
}

const SelectedBoat = ({ boat }) => {
  const router = useRouter()
  const translator = useTranslator()
  return (
    <div className="flex flex-col space-y-2">
      <Image
        imageFile={boat.imageFile}
        size="w400"
        alt={boat.name}
        className="object-cover object-center aspect-video"
      />
      <RawLink
        href={router.generate("app_account_profile_show", {
          userId: boat.user.id,
        })}
      >
        <div className="flex flex-col items-center space-y-2 lg:flex-row lg:justify-between lg:space-y-0">
          <div className="flex flex-row items-center space-x-4">
            <UserAvatar user={boat.user} size="small" />
            <Text>{boat.address?.search}</Text>
          </div>
          <Text tag="p" className="text-light" variant="caption">
            {translator.trans(
              "home.index.selectedBoats.nbVisits",
              { nbVisits: boat.nbVisits },
              "pages"
            )}{" "}
            - {new Date(boat.updatedAt).toLocaleDateString(translator.locale)}
          </Text>
        </div>
      </RawLink>
    </div>
  )
}

const SelectedBoatSkeleton = () => {
  return (
    <Card spacing="small">
      <CardContent>
        <div className="flex flex-col space-y-2 animate-pulse">
          <div className={"aspect-video bg-grey-light rounded"}></div>
          <div className="flex flex-col items-center space-y-2 lg:flex-row lg:justify-between lg:space-y-0">
            <div className="flex w-full shrink-0 flex-row items-center space-x-4">
              <div className={"shrink-0"}>
                <AvatarSkeleton size={"small"} />
              </div>
              <div className={"grid w-full gap-1"}>
                <div className={"w-full h-5 bg-grey-light rounded"}></div>
                <div className={"w-full h-5 bg-grey-light rounded"}></div>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

const Suggestions = ({ navigationType }) => {
  const router = useRouter()

  const fetchAdvertsSuggestions = () => {
    const url = router.generate("app_home_suggest", {
      type: SuggestType.ADVERT,
      navigationType,
    })

    return client.get(url)
  }

  const fetchUsersSuggestions = () => {
    const url = router.generate("app_home_suggest", {
      type: SuggestType.USER,
      navigationType,
    })

    return client.get(url)
  }

  const suggestionsRequest = useAsyncEffect(async () => {
    try {
      const [adverts, users] = await Promise.all([
        fetchAdvertsSuggestions(),
        fetchUsersSuggestions(),
      ])

      return {
        adverts: {
          items: adverts.data.data.adverts,
          moreUrl: addFromParam(adverts.data.data.moreUrl),
        },
        users: {
          items: users.data.data.users,
          moreUrl: addFromParam(users.data.data.moreUrl),
        },
      }
    } catch (err) {
      console.error("Error while fetching suggestions", err)
      throw err
    }
  }, [])

  const translator = useTranslator()
  const connectedUser = useConnectedUser()

  const hasBoats = suggestionsRequest.value?.adverts.items.boat?.length > 0
  const hasCrews = suggestionsRequest.value?.adverts.items.crew?.length > 0
  const hasUsers = suggestionsRequest.value?.users.items?.length > 0

  if (suggestionsRequest.status === "loading") {
    return (
      <Wrapper>
        <div className="flex justify-center items-center h-96">
          <Loader />
        </div>
      </Wrapper>
    )
  }

  if (suggestionsRequest.status === "success") {
    return (
      <Wrapper>
        <div
          className={cx("space-y-10", {
            "lg:grid lg:grid-cols-3 lg:gap-6 lg:space-y-0": connectedUser,
          })}
        >
          {hasBoats ? (
            <div className="col-span-2">
              <AdvertsSection
                adverts={suggestionsRequest.value.adverts.items.boat}
                title={translator.trans(
                  connectedUser
                    ? "home.index.adverts.title.connected"
                    : "home.index.advertsBoat.title.notConnected",
                  null,
                  "pages"
                )}
                moreLabel={translator.trans(
                  connectedUser
                    ? "home.index.advertsBoat.more.connected"
                    : "home.index.advertsBoat.more.notConnected",
                  null,
                  "pages"
                )}
                moreHref={
                  connectedUser
                    ? suggestionsRequest.value.adverts.moreUrl
                    : router.generate("app_search_advert", {
                        "a[type]": "boat",
                      })
                }
                layout="horizontal"
              />
            </div>
          ) : null}

          {hasCrews ? (
            <div className="col-span-2">
              <AdvertsSection
                adverts={suggestionsRequest.value.adverts.items.crew}
                title={translator.trans(
                  connectedUser
                    ? "home.index.adverts.title.connected"
                    : "home.index.advertsCrew.title.notConnected",
                  null,
                  "pages"
                )}
                moreLabel={translator.trans(
                  connectedUser
                    ? "home.index.advertsCrew.more.connected"
                    : "home.index.advertsCrew.more.notConnected",
                  null,
                  "pages"
                )}
                moreHref={
                  connectedUser
                    ? suggestionsRequest.value.adverts.moreUrl
                    : router.generate("app_search_advert", {
                        "a[type]": "crew",
                      })
                }
                layout="horizontal"
              />
            </div>
          ) : null}

          {hasUsers ? (
            <UsersSection
              users={suggestionsRequest.value.users.items}
              layout={connectedUser ? "vertical" : "horizontal"}
              moreHref={suggestionsRequest.value.users.moreUrl}
            />
          ) : null}
        </div>
      </Wrapper>
    )
  }

  return null
}

const addFromParam = (url) => {
  const urlWithFrom = new URL(url, window.location.href)
  urlWithFrom.searchParams.set("from", window.location.toString())

  return urlWithFrom.toString()
}

const PartnersSection = () => {
  const router = useRouter()
  const translator = useTranslator()
  const partners = useHomePartialData("footerPartners")

  const items =
    partners.status === "success"
      ? partners.value.slice(0, 5)
      : Array.from({ length: 5 })

  return (
    <>
      <Section theme="primary">
        <Wrapper>
          <Section.Title>
            {translator.trans("Footer.partners.title", null, "components")}
          </Section.Title>
        </Wrapper>

        {partners.status === "error" ? (
          <Text>
            {translator.trans("Footer.partners.error", null, "components")}
          </Text>
        ) : null}

        {partners.status === "success" && partners.value.length === 0 ? (
          <Text>
            {translator.trans("Footer.partners.empty", null, "components")}
          </Text>
        ) : null}

        {partners.status === "loading" ||
        (partners.status === "success" && partners.value.length > 0) ? (
          <div className="flex flex-col space-y-6">
            <div className="lg:hidden">
              <SlidingList
                items={items.slice(0, 5).map((partner, index) => (
                  <div
                    key={partner?.id ?? `skeleton-${index}`}
                    className="w-52"
                  >
                    {partners.status === "loading" ? (
                      <PartnerCardSkeleton />
                    ) : (
                      <PartnerCard partner={partner} />
                    )}
                  </div>
                ))}
              />
            </div>
            <div className="hidden lg:block">
              <Wrapper>
                <div className="grid grid-cols-5 gap-6">
                  {items
                    .slice(0, 5)
                    .map((partner, index) =>
                      partners.status === "loading" ? (
                        <PartnerCardSkeleton key={`skeleton-${index}`} />
                      ) : (
                        <PartnerCard key={partner.id} partner={partner} />
                      )
                    )}
                </div>
              </Wrapper>
            </div>
            {partners.status === "success" ? (
              <div className="flex justify-center">
                <Button
                  href={router.generate("app_club_partner_index")}
                  variant="outline"
                  color="primary"
                >
                  {translator.trans("PartnersSection.more", null, "components")}
                </Button>
              </div>
            ) : null}
          </div>
        ) : null}
      </Section>
      <div className="bg-primary-darker">
        <Wrapper>
          <Separator color="primary" />
        </Wrapper>
      </div>
    </>
  )
}

const TestimoniesSection = () => {
  const testimonies = useHomePartialData("testimonies")

  return (
    <StaticTestimoniesSection
      status={testimonies.status}
      testimonies={testimonies.value ?? []}
    />
  )
}

const SearchListsTabs = () => {
  const popularSearches = useHomePartialData("popularSearches")
  const savedSearches = useHomePartialData("savedSearches")

  return (
    <StaticSearchListsTabs
      popularSearches={popularSearches}
      savedSearches={savedSearches}
    />
  )
}
