import { Times } from "components/Icon"
import { useTranslator } from "components/Translator"
import { useEffect, useRef, useState } from "react"
import { Map, MapboxProvider, Source, Layer } from "components/Map"
import bbox from "@turf/bbox"
import { lineString } from "@turf/helpers"
import { formatDistanceStrict } from "utils/date"
import { BoatImage } from "domains/boat/BoatImage"
import { Wrapper } from "components/Wrapper"
import { Section } from "components/Section"
import { useRouter } from "components/Router"
import cx from "classnames"
import { Button } from "components/Button"
import { Text } from "components/Text"
import { MenuButton } from "components/Menu"
import { QuestionCircle } from "components/Icon/QuestionCircle"
import { useHomePartialData } from "hooks/useHomePartialData"
import { Loader } from "components/Loader"
import { BoatPositionLayers } from "domains/boat/BoatPosition"

export const BoatsMap = () => {
  const translator = useTranslator()
  const [selectedBoat, setSelectedBoat] = useState(null)
  const mapRef = useRef(null)
  const boats = useHomePartialData("boats")

  const lastPositionsGeoJSON =
    boats.status === "success" && boats.value
      ? boatsToLastPositionsGeoJSON(boats.value)
      : null

  const handleClick = (event) => {
    const [feature] = event.features

    if (!feature) {
      return
    }

    if (!feature.properties) {
      return
    }

    if (feature.properties.cluster_id) {
      return zoomOnCluster(
        feature.properties.cluster_id,
        feature.geometry.coordinates
      )
    }

    if (feature.properties.boat) {
      setSelectedBoat(JSON.parse(feature.properties.boat))
    }
  }

  const zoomOnCluster = (clusterId, coordinates) => {
    const mapboxSource = mapRef.current.getMap().getSource("lastPositions")

    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return
      }

      mapRef.current?.easeTo({
        center: coordinates,
        zoom,
        duration: TRANSITION_DURATION,
      })
    })
  }

  useEffect(() => {
    if (!selectedBoat) {
      return
    }

    mapRef.current?.fitBounds(getBoatBounds(selectedBoat), {
      padding: 50,
      duration: TRANSITION_DURATION,
    })
  }, [selectedBoat])

  return (
    <Section>
      <Wrapper>
        <Section.Title size="small">
          <div className="flex items-center space-x-2">
            <div>
              {translator.trans("home.index.boatsMap.title", null, "pages")}
            </div>
            <MenuButton
              illustration={<QuestionCircle className="w-5" />}
              color="light"
              href={
                "https://vogavecmoi.zendesk.com/hc/fr/articles/7552612636178-V3-Quels-sont-les-bateaux-qui-apparaissent-sur-la-carte-et-comment-apparaitre-sur-celle-ci-"
              }
              target="_blank"
            />
          </div>
        </Section.Title>
      </Wrapper>
      <div className="relative h-screen-2/3">
        {boats.status === "loading" ? (
          <div
            className={
              "absolute inset-0 flex items-center justify-center z-10 bg-grey-light/60"
            }
          >
            <Loader />
          </div>
        ) : null}
        <MapboxProvider>
          <Map
            initialViewState={{
              latitude: 46.227638,
              longitude: 2.213749,
              zoom: 1.75,
            }}
            width="100%"
            height="100%"
            interactiveLayerIds={["clusters", "unclustered-points"]}
            onClick={handleClick}
            ref={mapRef}
          >
            {lastPositionsGeoJSON !== null ? (
              <Source
                id="lastPositions"
                type="geojson"
                data={lastPositionsGeoJSON}
                cluster={true}
                clusterMaxZoom={14}
                clusterRadius={50}
              >
                <Layer {...getClusterLayerProps(selectedBoat)} id="clusters" />
                <Layer
                  {...getClusterCountLayerProps(selectedBoat)}
                  id="cluster-count"
                />
                <Layer
                  {...getUnclusteredPointsLayerProps(selectedBoat)}
                  id="unclustered-points"
                />
              </Source>
            ) : null}

            {selectedBoat ? (
              <BoatPositionLayers mmsiPositions={selectedBoat.mmsiPositions} />
            ) : null}
          </Map>
        </MapboxProvider>
        {selectedBoat ? (
          <BoatInfos
            boat={selectedBoat}
            onClose={() => setSelectedBoat(null)}
          />
        ) : null}
      </div>
    </Section>
  )
}

const TRANSITION_DURATION = 500

const boatsToLastPositionsGeoJSON = (boats) => {
  const features = boats
    .filter((boat) => boat.mmsiPositions.length > 0)
    .map((boat) => {
      const lastPosition = boat.mmsiPositions[0]

      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [lastPosition.lng, lastPosition.lat],
        },
        properties: { boat },
      }
    })

  return {
    type: "FeatureCollection",
    features,
  }
}

const getClusterLayerProps = (selectedBoat) => {
  return {
    type: "circle",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": "#51bbd6",
      "circle-radius": 30,
    },
    layout: {
      visibility: selectedBoat ? "none" : "visible",
    },
  }
}

const getClusterCountLayerProps = (selectedBoat) => {
  return {
    type: "symbol",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-size": 16,
      visibility: selectedBoat ? "none" : "visible",
    },
  }
}

const getUnclusteredPointsLayerProps = (selectedBoat) => {
  return {
    type: "circle",
    filter: ["!", ["has", "point_count"]],
    paint: mainPointPaint,
    layout: {
      visibility: selectedBoat ? "none" : "visible",
    },
  }
}

const mainPointPaint = {
  "circle-color": "#139EB3",
  "circle-radius": 10,
  "circle-stroke-width": 2,
  "circle-stroke-color": "#fff",
}

const BoatInfos = ({ boat, onClose }) => {
  const router = useRouter()
  const translator = useTranslator()
  const lastPosition = boat.mmsiPositions[0]

  return (
    <div
      className={cx(
        "absolute inset-y-0 left-0 z-10 w-1/2 sm:w-1/4 md:w-80 bg-white",
        { "pt-20": !boat.imageFile }
      )}
    >
      <div className="absolute top-0 right-0 p-2 z-10">
        <Button
          onClick={onClose}
          color="secondary"
          iconLeft={<Times className="w-4" />}
        />
      </div>
      <BoatImage
        boat={boat}
        size="w400"
        className="w-full h-40 bg-grey-light flex items-center justify-center object-center object-cover"
      />
      <div className="p-5 space-y-6">
        <Text variant="headline3">{boat.name}</Text>
        <div className="space-y-2">
          <Text tag="p" variant="caption">
            {translator.trans(
              "home.index.boatsMap.lastPosition",
              {
                time: formatDistanceStrict(
                  new Date(),
                  new Date(lastPosition.date)
                ),
              },
              "pages"
            )}
          </Text>
          <Button
            href={router.generate("app_account_boat_show", {
              userId: boat.user.id,
              boatId: boat.id,
            })}
            variant="outline"
            fullWidth
            color="secondary"
          >
            {translator.trans("home.index.boatsMap.cta", null, "pages")}
          </Button>
        </div>
      </div>
    </div>
  )
}

const getBoatBounds = (boat) => {
  const line = lineString(
    boat.mmsiPositions.map((position) => [position.lng, position.lat])
  )

  const bounds = bbox(line)

  return [bounds.slice(0, 2), bounds.slice(2)]
}
