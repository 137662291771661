import PropTypes from "prop-types"
import { Avatar } from "components/Avatar"
import { getImageSrc } from "components/Image"
import { BoatPlaceholder } from "components/Icon/BoatPlaceholder"

function Fallback() {
  return (
    <div className="w-full h-full flex justify-center items-center rounded-full bg-grey-light rounded-full overflow-hidden">
      <BoatPlaceholder className="w-4" />
    </div>
  )
}

export function BoatAvatar(props) {
  const { boat, size, ...rest } = props

  const sizeToThumb = {
    xsmall: "w40",
    small: "w75",
    medium: "w150",
    large: "w400",
  }

  const thumb = sizeToThumb[size]

  return (
    <Avatar
      src={boat?.imageFile ? getImageSrc(boat.imageFile, thumb) : null}
      size={size}
      fallback={<Fallback />}
      {...rest}
    />
  )
}

BoatAvatar.propTypes = {
  boat: PropTypes.object,
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large"]),
}
