import { Image } from "components/Image"
import { BoatAvatar } from "domains/boat/BoatAvatar"

export function BoatImage(props) {
  const { boat, size, className } = props

  if (!boat) {
    return null
  }

  return boat.imageFile ? (
    <Image imageFile={boat.imageFile} size={size} className={className} />
  ) : (
    <div className={className}>
      <BoatAvatar boat={boat} />
    </div>
  )
}
