import { Wrapper } from "components/Wrapper"

export const SlidingList = ({ items }) => {
  return (
    <div className="overflow-x-auto pb-1.5">
      <Wrapper>
        <ul className="flex flex-nowrap space-x-4">
          {items.map((item) => (
            <li key={item.key}>{item}</li>
          ))}
        </ul>
      </Wrapper>
    </div>
  )
}
